import './AssignRole.css'

import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters, controlled } from "mstform"
import { onSnapshot } from 'mobx-state-tree'
import * as PropTypes from "prop-types"
import { Popconfirm, Icon } from 'antd'
import SelectInput from '../common/form/SelectInput'
import Label from '../common/form/Label';
import {RoleAssignment} from '../store/actions/Subject'
import ContentLoader from '../common/content_loader'
import Avatar, { AVI_TYPES} from '../common/avatar'
import { debounce } from '../lib/debounce'
import NotMobile from '../common/display/NotMobile'
import Mobile from '../common/display/Mobile'
import { ModalContext } from '../common/modal'
import MobileConfirmDialog from '../common/mobile_confirm_dialog';

import bin from '../common/assets/bin.svg'
import avatar_blank from '../common/assets/avatar-blank.svg'


const form = new Form(RoleAssignment, {
    employee_id: new Field(converters.string, { controlled: controlled.object, required: true }),
    subject_id: new Field(converters.string, { controlled: controlled.object, required: true }),
    special_role_id: new Field(converters.string, { controlled: controlled.object, required: true }),
})

@inject('store', 'display')
@observer
class AssignRole extends React.Component {
    state = { 
        selectedSubject: {}
     }

    async componentWillMount() {
        const { store, subjectId } = this.props
        const { specialRoles, subjectStore, employeeStore: {loadActiveEmployees} } = store
        await loadActiveEmployees(1)
        const { roleAssignmentFormInstance, selectSubjectForEdit, assignRole} = subjectStore;
        const selectedSubject = await selectSubjectForEdit(subjectId)
        let specialRole;
        specialRoles.forEach((sr) => {
            if (sr.attributes.role_type === 'subject_teacher' && sr.attributes.assignment_level === 'subject') specialRole = sr
        })
    
        if (selectedSubject){
            this.formState = form.state(roleAssignmentFormInstance, {addMode: true})
            this.formState.field("subject_id").setValue(subjectId)
            this.formState.field("special_role_id").setValue(specialRole.id)
            this.setState({ selectedSubject })
            onSnapshot(roleAssignmentFormInstance, debounce((snapshot) => {
                if (this.formState.isValid && roleAssignmentFormInstance.employee_id) {
                    return assignRole(snapshot)
                }
            }, 250))
        }
    }

    render() {
        const { store } = this.props
        const { subjectStore, employeeStore } = store
        const { isLoading, unassignEmployeeById } = subjectStore
        const { selectedSubject } = this.state
        if (!this.formState || isLoading) return <Loading />
        const { specialRoleAssignments} = selectedSubject
        const assignedEmployees = specialRoleAssignments.map(({id, relationships: { employee: {data}}}) => ({employee: data.id, special_role_assignment_id: id}))
        

        const employee_id = this.formState.field("employee_id")
        const options = employeeStore.activeEmployees.map(({id, employeeName}) => ({value: id, text: employeeName}))
        return (   
            <div className="AssignRole">
                <Label text="Employee" inline={false}>
                    {assignedEmployees.map(({employee : {employeeName, id, gender}, special_role_assignment_id}) => (
                                <AssignRoleSelectInput 
                                    key={id}
                                    {...employee_id.inputProps} 
                                    options={[{text: employeeName, value: id}]}
                                    disabled
                                    value={id}
                                    gender={gender}
                                    handleDelete={() => unassignEmployeeById(selectedSubject.id, special_role_assignment_id)}
                                />
                            )
                        )
                    }
                    <AssignRoleSelectInput 
                        {...employee_id.inputProps} 
                        options={options}
                    />
                </Label> 
            </div> 
        )
    }
}
AssignRole.propTypes = {
    subjectId: PropTypes.string.isRequired
}

export default AssignRole

const AssignRoleSelectInput = ({disabled,gender, handleDelete, ...rest}) => (
        <div className="AssignRole-input">
            <div>
                <Avatar 
                    type={AVI_TYPES.EMPLOYEE}  
                    className="AssignRole-input-image" 
                    sex={gender || "m"} 
                    src={disabled ? null : avatar_blank}
                />
                <SelectInput
                    {...rest}
                    disabled={disabled}
                    placeholder="Select Employee"
                    className="AssignRole-input--select"
                />
            </div>
            {disabled &&( 
                <>
                    <NotMobile>
                        <Popconfirm
                            title="Are you sure you want to unassign this employee?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={handleDelete}
                            overlayClassName="AssignRole-delete-dialog "
                        >  
                            <div>
                                <img src={bin} alt="bin" />
                            </div>
                        </Popconfirm>
                    </NotMobile> 
                    <Mobile>
                        <ModalContext.Consumer>
                            {context => (
                                <div
                                    onClick={() => context.setContent(
                                        "Unassigning employee...",
                                        <MobileConfirmDialog
                                            handleConfirm={() => {
                                                handleDelete()
                                                context.closeModal()
                                            }}
                                            handleCancel={context.closeModal}
                                        />,
                                        true
                                    )}
                                >
                                    <img src={bin} alt="bin" />
                                </div>
                            )}
                        </ModalContext.Consumer>
                    </Mobile>
                </>              
            )}
        </div>
    )

const Loading = () => (
    <ContentLoader height="70" width='300'>
        <rect x="15" y="0" rx="1" ry="1" width="30" height="8" />
        <rect x="15" y="15" rx="1" ry="1" width="150" height="15" />
    </ContentLoader>
)
