import React, {Component} from 'react'
import Button from './button';
import {inject, observer} from "mobx-react";

function handleReload() {
    window.location.reload()
}


@inject('store')
@observer
export default class ErrorBoundary extends Component {

    static getDerivedStateFromError(err) {
        return {
            hasError: true,
            error: err
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            hasError: false,
            error: null
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        const {children} = this.props
        if (nextProps.children !== children) {
            this.setState({hasError: false});
        }
    }

    handleClearBlueBicStore = () => {
        const {store} = this.props
        const {authStore} = store
        const {logout} = authStore
        logout();
    }

    render() {
        const {hasError, error} = this.state
        const {children} = this.props

        if (hasError) return (
            <div>
                <div className="text--danger text-h3 text--bold text--spacedLetters text--uppercase">
                    Oops! An error occured while loading this page. Try the following:
                </div>
                <div>
                    <p>
                        <ol>
                            <li>Check Internet connection</li>
                            <li><a onClick={handleReload}>Reload the page</a></li>
                            <li><a onClick={this.handleClearBlueBicStore}>Clear App Cache</a> (This logs you out)
                            </li>
                            <li>If the problem persists, contact our <a href="mailto:support@bluebic.com">support
                                center</a> for help.
                            </li>

                            <li><a
                                onClick={(e) => {
                                    e.preventDefault();
                                    debugger
                                }}
                                className=""
                            >
                                View Error Details.
                            </a>
                                <h4>{error.message}</h4>
                                <p>${error.toString()}</p>
                            </li>
                        </ol>
                    </p>
                </div>
            </div>
        )
        return children
    }
}
