import axios from './axios'
import { TESTENV } from './constants'
import { formDataTransform } from './formDataTransform';

export function BlueBicMetaService(env) {
    async function getBlueBicMeta() {
        if (env !== TESTENV) {
            const { data } = await axios.get('/api/v1/bluebic_meta');
            return data;
        }
        return {};
    }

    return {
        getBlueBicMeta
    }
}

export default BlueBicMetaService(TESTENV)
