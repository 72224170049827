import 'antd/dist/antd.less';
import './App.css';

import React from 'react';
import { inject, observer } from 'mobx-react';

import Feedback from '../common/feedback/Feedback';

import EmployeeApp from './EmployeeApp';
import Login from '../login';
import A4Container from './A4'
import POSContainer from './POS'
import StudentApp from "./StudentApp";
import GuardianApp from "./GuardianApp";
import isEmpty from "lodash.isempty";
import ErrorBoundary from "../common/error_boundary";

const VariantWrapper = inject('store')(
    observer(({ store, children }) => {

        const toRender = store.isLoading ? <h1>Loading...</h1> : children

        if (store.view.templateVariant === 'html+A4'){
            return (<A4Container>{toRender}</A4Container>)
        }

        if (store.view.templateVariant === 'html+pos'){
            return (<POSContainer>{toRender}</POSContainer>)
        }

        return <ErrorBoundary>{children}</ErrorBoundary>
        
    })
)

const App = inject("store", "display")(
    observer(({ store, display }) => {
        const { authStore, meta: {currentUser} } = store
        
        if (isEmpty(currentUser) && authStore.hasToken) {
            store.logout()
        }
        
        if (!authStore.hasToken) {
            return (
                <div className={display.screensize}>
                    <div id="portal-root">
                        <Feedback />
                    </div>
                    <ErrorBoundary><Login /></ErrorBoundary>
                </div>
            )
        }

        if (store.isLoading) return <h1>Loading...</h1>

        if (currentUser.is_admin || currentUser.is_employee){
            return (
                <VariantWrapper>
                    <EmployeeApp />
                </VariantWrapper>
            )
        }

        if (currentUser.is_student){
            return (
                <VariantWrapper>
                    <StudentApp />
                </VariantWrapper>
            )
        }

        if (currentUser.is_guardian){
            return (
                <VariantWrapper>
                    <GuardianApp />
                </VariantWrapper>
            )
        }

        return (<VariantWrapper><h1>Unknown User type</h1></VariantWrapper>)
    }))

export default App;