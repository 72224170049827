import { types } from "mobx-state-tree"

import { countryOptions, currencyOptions } from "../actions/Institution";
import {SmsMaster} from "./SmsMaster";

export const Institution = types
    .model("Institution", {
        id: types.identifier,
        type: types.literal("institution"),
        attributes: types.maybe(types.model({
            name: "",
            name_abbreviation: "",
            subdomain: types.maybeNull(types.string),
            tenant_name: types.maybe(types.string),
            slogan: types.maybeNull(types.string),
            logo_url: types.maybeNull(types.string),
            email: types.maybeNull(types.string),
            website: types.maybeNull(types.string),
            phone: types.maybeNull(types.string),
            mobile_phone: types.maybeNull(types.string),
            address: types.maybeNull(types.string),
            is_demo_institution: types.maybe(types.boolean),
            country: types.maybeNull(types.enumeration("countries", countryOptions.map(country => country.value))),
            // currency: types.maybeNull(types.enumeration("currencies", currencyOptions.map(currency => currency.value))),
            currency: types.maybeNull(types.string),
            paystack_sub_account_code: types.maybeNull(types.string)
        })),
        relationships: types.maybe(
            types.model({
                sms_master: types.maybe(types.model({
                    data: types.maybeNull(types.model({
                        id: types.late(() => types.reference(SmsMaster))
                    }))
                })),
            }),
        ),
    })
    .views(self => ({
        get tenantName() {
            return self.attributes.tenant_name
        },
        get paystackSubAccountCode() {
            return self.attributes.paystack_sub_account_code
        },
        get isDemoInstitution() {
            return self.attributes.is_demo_institution === true
        },
        get legacyLoginUrl(){
            return `https://${self.attributes.subdomain}.legacy.bluebic.com/sign_in`
        },
        get legacyAcademicSessionsUrl(){
            return `https://${self.attributes.subdomain}.legacy.bluebic.com/legacy/configuration/academic_sessions`
        },
        get legacyCoursesSettingsUrl(){
            return `https://${self.attributes.subdomain}.legacy.bluebic.com/legacy/configuration/courses`
        },
        get legacyCourseSetsSettingsUrl(){
            return `https://${self.attributes.subdomain}.legacy.bluebic.com/legacy/configuration/class_set`
        },
        get smsMaster() {
            try {
                return self.relationships.sms_master.data.id
            } catch (e) {
                return null
            }
        },
    }))
