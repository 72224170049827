import {getParent, types} from "mobx-state-tree"
import { Gender } from "./Gender";
import {Employee} from "./Employee";
import {Student} from "./Student";
import {Guardian} from "./Guardian";
import isEmpty from "lodash.isempty";
import {formatDate} from "../../lib/formatDate";


const EmployeeReference = types.model({id: types.late(() => types.reference(Employee)), type: "employee"})
const StudentReference = types.model({id: types.late(() => types.reference(Student)), type: "student"})
const GuardianReference = types.model({id: types.late(() => types.reference(Guardian)), type: "guardian"})

const UserableUnion = types.union({'dispatcher': (snapshot) => {
        if (!snapshot) return types.null
        
        switch (snapshot.type) {
            case "employee": return EmployeeReference;
            case "student": return StudentReference;
            case "guardian": return GuardianReference;
            default: throw new Error();
        }
    }}, types.null, EmployeeReference, StudentReference, GuardianReference)

export const User = types
    .model("User", {
        id: types.identifier,
        type: types.literal("user"),
        attributes: types.maybe(types.model({
            user_type: types.enumeration("userType", ['Admin', 'Employee', 'Student', 'Guardian']),
            username: types.string,
            userable_type: types.maybeNull(types.string),
            userable_id: types.maybeNull(types.number),
            first_name: "",
            middle_name: types.maybeNull(types.string),
            last_name: "",
            gender: types.maybeNull(Gender),
            email: types.maybeNull(types.string),
            bluebic_email: types.maybeNull(types.string),
            preferred_email: types.maybe(types.string),
            mobile_phone: types.maybeNull(types.string),
            phone: types.maybeNull(types.string),
            title: types.maybeNull(types.string),
            address: types.maybeNull(types.string),
            photo_url: types.maybeNull(types.string),
            is_admin: types.boolean,
            locked_at: types.maybeNull(types.string),
            password_changed: types.optional(types.boolean, false),
            helpscout_signature: types.maybe(types.string)
        })),
        relationships: types.model({
            userable: types.model({
                data: types.maybeNull(UserableUnion)
            })
        }),
        isLoading: false
    })
    .views(self => ({
        get bluebic() {
            return getParent(self, 2)
        },
        get isInitialized(){
            return !isEmpty(self.attributes)
        },
        get isAssociationsLoaded(){
            return !isEmpty(self.relationships) && !isEmpty(self.relationships.userable)
        },
        get fullName() {
            return `${self.attributes.last_name} ${self.attributes.first_name}`
        },
        get userType(){
            return self.attributes.user_type
        },
        get is_student() {
            return self.attributes.user_type === 'Student'
        },
        get is_admin() {
            return self.attributes.user_type === 'Admin' || self.attributes.is_admin
        },
        get is_employee() {
            return self.attributes.user_type === 'Employee'
        },
        get is_employee_or_admin() {
            return self.is_employee || self.is_admin
        },
        get is_guardian() {
            return self.attributes.user_type === 'Guardian'
        },
        get userable() {
            try{
                return self.relationships.userable.data.id
            } catch (e) {
                return undefined
            }
        },

        get global_id() {
            return `${self.bluebic.meta.currentInstitution.attributes.tenant_name}-${self.id}`
        },

        get bioData() {
            const { attributes } = self
            return {
                userProfile: {
                    "title": attributes.title,
                    "last name": attributes.last_name,
                    "first name": attributes.first_name,
                    "middle name": attributes.middle_name,
                    "gender": attributes.gender,
                    "username": attributes.username,
                    "is admin": attributes.is_admin ? 'Yes' : 'No',
                    "status": attributes.status
                },
                contacts: {
                    "phone": attributes.phone,
                    "mobile phone": attributes.mobile_phone,
                    "email": attributes.email
                },
                address: attributes.address,
            }
        },
        
        has_any_role(roles){
            if (self.is_admin) return true
            if (!self.is_employee) return false
            return self.userable.has_any_role(roles)    
        },
        
        has_role(role){
            if (self.is_admin) return true
            if (!self.is_employee) return false
            return self.userable.has_role(role)
        },
        
        has_special_role(role, resource = null) {
            return self.has_any_special_role([role], resource)
        },

        has_any_special_role(roles, resource = null){
            if (self.is_admin) return true
            if (!self.is_employee) return false
            
            return  self.userable.has_any_special_role_on_resource(roles, resource)
        }
    }))