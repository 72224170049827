import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ModalContext } from '../common/modal'
import Tabs from '../common/tabs'
import Tab from '../common/tabs/Tab'
import people from './assets/people.svg'
import BatchSummary from './BatchSummary'
import BatchStudentList from './BatchStudentList'
import BatchAttendance from './BatchAttendance'
import BatchPolicy from "../policies/BatchPolicy";
import CreateBatchForm from "./CreateBatchForm";
import BatchBehaviouralScoresheet from './BatchBehaviouralScoresheet'
import BatchAssignRole from "./BatchAssignRole";
import {handleImportSetup} from "./ImportSetupForm";

@inject("store")
@observer
class SelectedBatch extends Component {
    tabs = []

    componentWillMount() {
        const { store } = this.props
        const { batchStore, view, meta: {currentUser} } = store
        batchStore.loadBatchById(view.selectedBatchId)
        const batchDetails = view.selectedBatch
        
        const policy = new BatchPolicy(currentUser, batchDetails)
        
        let activeTab
        if (policy.can_attendance_register) activeTab = 3
        else if (policy.can_behavioural_scoresheet) activeTab = 2
        else if (policy.can_demographics) activeTab = 1
        else throw new Error('You do not have permission to view this record')

        this.tabs = []
        if (policy.can_demographics){
            this.tabs.push(new Tab("Student List",  <BatchStudentList batchDetails={batchDetails} />, activeTab === 1))
        }
        if (policy.can_attendance_register){
            this.tabs.push(new Tab("Attendance",<BatchAttendance batchDetails={batchDetails} />, activeTab === 3))
        }
        if (policy.can_behavioural_scoresheet){
            this.tabs.push(new Tab("Behavioural Scoresheet", <BatchBehaviouralScoresheet batchDetails={batchDetails} />, activeTab === 2))
        }
    }

    options = (context) => {
        const { store : {alert, batchStore: {deleteBatch}, meta: {currentUser}, view : { selectedBatch}} } = this.props
        const policy = new BatchPolicy(currentUser, selectedBatch)

        return [
            {
                text: 'Edit Batch',
                disabled: !policy.can_update,
                title: policy.can_update ? undefined : "No permission.",
                onClick: () => {
                    context.setContent(
                        `Edit Arm  (${selectedBatch.name})`,
                        <CreateBatchForm closeModal={context.closeModal} batchInfo={selectedBatch} editMode />
                    )
                }
            },
            {
                text: 'Assign employee',
                disabled: !policy.can_update_role,
                title: policy.can_update_role ? undefined : "No permission.",
                onClick: () => {
                    context.setContent(
                        `Assign Employee`,
                        <BatchAssignRole batchInfo={selectedBatch} editMode />
                    )
                }
            },
            {
                text: 'Import Setup',
                disabled: false,
                title: policy.can_import_setup ? undefined : "No permission.",
                onClick: () => {
                    if (!policy.can_import_setup){
                        alert({ message: { warning: "You do not have permission to import setup." } })
                        return
                    }
                    handleImportSetup(context, { batch_id: selectedBatch.id})
                }
            },
            {
                text: 'Delete Arm',
                disabled: !policy.can_destroy,
                title: policy.can_destroy ? undefined : "No permission.",
                onClick: () => deleteBatch(selectedBatch.id)
            }
        ]
    }

    render() {
        const { store : {view : { selectedBatch}} } = this.props
        const {isLoading} = selectedBatch
        
        const canPrint = false;
        return (
            <ModalContext.Consumer>
                {context => (
                    <Tabs
                        title={{ text: 'Arm Detail', icon: people }}
                        tabs={this.tabs}
                        canPrint={canPrint}
                        content={<BatchSummary batch={selectedBatch} />}
                        options={isLoading ? [] : this.options(context)}
                    />
                )}
            </ModalContext.Consumer>
        )
    }
}

export default SelectedBatch
