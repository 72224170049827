import './AddStudent.css'

import React from 'react'
import { RepeatingForm, Field, Form, converters, controlled } from "mstform";
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types'
import Card from '../common/card';
import Label from '../common/form/Label';
import { Student, religionOptions, bloodGroupOptions  } from '../store/actions/Student';
import { genderOptions } from '../store/models/Gender';
import InlineError from '../common/form/InlineError';
import TextInput from '../common/form/TextInput';
import DateInput from '../common/form/DateInput';
import SelectInput from '../common/form/SelectInput';
import { mapModelNameToOptions } from '../lib/mapModelNameToOptions';
import statesInNigeriaJSON from '../json/statesInNigeria.json'
import MultiselectInput from '../common/form/MultiselectInput';
import MultilineInput from '../common/form/MultilineInput';
import PhoneInput from '../common/form/PhoneInput';
import Button from '../common/button'
import StudentGuardianRelations from './StudentGuardianRelations';
import MobilePageHeader from '../containers/MobilePageHeader';
import student_icon from './assets/graduate-student-avatar.svg';
import EditFormLoading from '../common/loading/EditFormLoading';
import { mapStringListToOptions } from '../lib/mapStringListToOptions';
import { mapCountriesJSONtoOptions } from '../lib/mapCountriesJSONtoOptions';
import { mapStatesInNigeriaJSONtoOptions } from '../lib/mapStatesInNigeriaJSONtoOptions';
import PhotoInput from '../common/form/PhotoInput';
import isEmpty from "lodash.isempty";
import {NewBatchLink} from "../batches/NewBatch";
import {Icon} from "antd";

const validators = [(value) => (value.length > 0) ? false : 'Field is required']
const form = new Form(Student, {
    id: new Field(converters.maybeNull(converters.string)),
    username: new Field(converters.string, { required: true, validators }),
    email: new Field(converters.maybeNull(converters.string)),
    admission_date: new Field(converters.string, {controlled: controlled.object, required: true}),
    first_name: new Field(converters.string, { required: true, validators }),
    middle_name: new Field(converters.maybeNull(converters.string)),
    last_name: new Field(converters.string, { required: true, validators }),
    student_category_ids: new Field(converters.maybeNull(converters.stringArray), {controlled: controlled.object}),
    gender: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    photo: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    blood_group: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    nationality: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    state_of_origin: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    lga_of_origin_id: new Field(converters.maybeNull(converters.number), {controlled: controlled.object}),
    religion: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    address: new Field(converters.maybeNull(converters.string)),
    batch_id_cache: new Field(converters.maybeNull(converters.string), {controlled: controlled.object, required: true}),
    mobile_phone: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    phone: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    date_of_birth: new Field(converters.maybeNull(converters.string), {controlled: controlled.object}),
    health_info: new Field(converters.maybeNull(converters.string)),
    student_guardian_relations_attributes: new RepeatingForm({
        guardian_id: new Field(converters.maybeNull(converters.string)),
        relation: new Field(converters.string),
        emergency_contact: new Field(converters.boolean),
        authorized_to_pick_up: new Field(converters.boolean)
    })
});

@inject("store", "display")
@observer
class AddStudent extends React.Component {
    state = { createdStudent: null, selectedStudent: null, baseFormErrors: undefined }

    constructor(props) {
        super(props)

        const { store, editMode } = props
        const { studentStore: { addStudentFormInstance, createStudent, editStudent }  } = store
        
        const action = editMode ? editStudent : createStudent


        this.formState = form.state(addStudentFormInstance, {
            addMode: false,
            save: async (node) => {
                const { data: createdStudent, errors } = await action(node.toJSON())
                if (createdStudent) this.setState({ createdStudent })
                if (errors) this.setState({baseFormErrors: errors.base})
                return errors
            }
        })
        
    }

    async componentDidMount() {
        const { store, editMode } = this.props
        if (!editMode) return
        
        const { getBlueBicMeta, studentStore: {selectStudentForEdit}, view: {selectedStudentId} } = store
        getBlueBicMeta()
        const selectedStudent = await selectStudentForEdit(selectedStudentId)
        this.setState({ selectedStudent })
        
    }

    componentWillUnmount() {
        const { editMode, store: { studentStore: {resetAddStudentForm} } } = this.props
        if (editMode) {
            resetAddStudentForm()
        }
    }

    handleAssignGuardian = (relation) => {
        const { store } = this.props
        const { studentStore } = store
        const { addStudentFormInstance } = studentStore
        addStudentFormInstance.assignGuardian(relation)
    }

    handleSubmit = async () => {
        const success = await this.formState.save()
        if (success) {
            const { store } = this.props
            const { studentStore, view } = store
            const { resetAddStudentForm } = studentStore
            resetAddStudentForm()
            view.openAddStudentPage()
        }
    }

    handleSubmitAndExit = async () => {
        const success = await this.formState.save()
        const { createdStudent } = this.state
        if (success && createdStudent) {
            const { store } = this.props
            const { view, studentStore } = store
            const { resetAddStudentForm } = studentStore
            resetAddStudentForm()
            view.openStudentPage(createdStudent)
        }
    }

    render() {
        const {baseFormErrors} = this.state;
        const { store, display, editMode } = this.props
        const {
            studentStore: {
                addStudentFormInstance,
                isLoading: studentStoreLoading
            },
            meta: {
                activeBatches,
                currentAcademicSession,
                currentInstitution: {legacyAcademicSessionsUrl},
                attributes: {
                    lgas,
                    student_categories
                },
                isLoading: metaIsLoading
            },
            batchStore: {items: allBatchesInStore},
            getBlueBicMeta
        } = store
        
        const Header = (
            <MobilePageHeader>
                <div className="flex-row text-small text--uppercase">
                    <img src={student_icon} className="utils-prepend" alt="" />
                    {editMode ? "Edit" : "Add"} Student
                </div>
            </MobilePageHeader>
        )
        if (editMode) {
            const { selectedStudent } = this.state
            const isLoading = (selectedStudent === null) || selectedStudent.isLoading
            if (isLoading) {
                return (
                    <div className="AddStudent">
                        {Header}
                        <EditFormLoading />
                    </div>
                )
            }
        }
        
        const {removeGuardian} = addStudentFormInstance
        
        const last_name = this.formState.field("last_name")
        const first_name = this.formState.field("first_name")
        const middle_name = this.formState.field("middle_name")
        const username = this.formState.field("username")
        const admission_date = this.formState.field("admission_date")
        const date_of_birth = this.formState.field("date_of_birth")
        const photo = this.formState.field("photo")
        const gender = this.formState.field("gender")
        const religion = this.formState.field("religion")
        const blood_group = this.formState.field("blood_group")
        const nationality = this.formState.field("nationality")
        const state_of_origin = this.formState.field("state_of_origin")
        const lga_of_origin_id = this.formState.field("lga_of_origin_id")
        const batch_id_cache = this.formState.field("batch_id_cache")
        const student_category_ids = this.formState.field("student_category_ids")
        const email = this.formState.field("email")
        const mobile_phone = this.formState.field("mobile_phone")
        const phone = this.formState.field("phone")
        const address = this.formState.field("address")
        const health_info = this.formState.field("health_info")

        
        return (
            <div className="AddStudent">
                {Header}

                <Card title="Student Information">
                    {baseFormErrors && (
                        <div className="mb-1">
                            <span className="text--danger">{baseFormErrors}</span>
                        </div>
                    )}
                    <div className="AddStudent-studentInfo">
                        <Label text="Surname" required={last_name.required}>
                            <InlineError field={last_name}>
                                <TextInput {...last_name.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="First Name" required={first_name.required}>
                            <InlineError field={first_name}>
                                <TextInput {...first_name.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Middle Name" required={middle_name.required}>
                            <TextInput {...middle_name.inputProps} />
                        </Label>
                        <Label text="Admission Number" required={username.required}>
                            <InlineError field={username}>
                                <TextInput {...username.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Admission Date" required={admission_date.required}>
                            <InlineError field={admission_date}>
                                <DateInput {...admission_date.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Student Category" required={student_category_ids.required}>
                            <InlineError field={student_category_ids}>
                                <MultiselectInput
                                    {...student_category_ids.inputProps}
                                    placeholder="Select category"
                                    options={mapModelNameToOptions(student_categories)}
                                />
                            </InlineError>
                        </Label>
                        <Label text="Batch" required={batch_id_cache.required} style={display.isNotMobile ? { gridRow: "span 2" } : null}>
                            <InlineError field={batch_id_cache}>
                                <SelectInput
                                    {...batch_id_cache.inputProps}
                                    placeholder="Select batch"
                                    options={editMode ? mapModelNameToOptions(allBatchesInStore) : mapModelNameToOptions(activeBatches)}
                                    disabled={editMode}
                                />
                            </InlineError>
                            {(isEmpty(activeBatches) && !editMode) && (
                                <p><strong>Notice!</strong> Current batches not found. Be sure to:
                                    <ol>
                                        {isEmpty(currentAcademicSession) && (
                                            <li>
                                                <a href={legacyAcademicSessionsUrl} rel="noopener noreferrer" target="_blank">
                                                    Add current Academic Session
                                                </a> 
                                            </li>
                                        )}
                                        
                                        <li>{NewBatchLink('Add Batch')} to Academic Session.</li>

                                        <li>
                                            <a href='/students/add'
                                               onClick={e => {
                                                   e.preventDefault()
                                                   getBlueBicMeta()
                                               }}
                                            >
                                                { metaIsLoading && (<Icon type="loading" style={{ color: 'blue' }} spin /> ) } Reload
                                            </a> after resolving above issue(s).
                                        </li>
                                    </ol>
                                </p>
                            )}
                        </Label>
                        
                        <UploadPhoto field={photo} />
                        
                        <Label
                            text="Address"
                            required={address.required}
                            // style={display.isNotMobile ? { gridColumn: "span 2" } : null}
                            style={display.isNotMobile ? { gridRow: "span 2" } : null}
                        >
                            <MultilineInput {...address.inputProps} />
                        </Label>
                        
                        <Label text="Date of Birth" required={date_of_birth.required}>
                            <InlineError field={admission_date}>
                                <DateInput {...date_of_birth.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Gender" required={gender.required}>
                            <SelectInput
                                {...gender.inputProps}
                                placeholder="Select gender"
                                options={mapStringListToOptions(genderOptions)}
                            />
                        </Label>
                        <Label text="Religion" required={religion.required}>
                            <SelectInput
                                {...religion.inputProps}
                                placeholder="Select religion"
                                options={mapStringListToOptions(religionOptions)}
                            />
                        </Label>
                        <Label text="Blood Group" required={blood_group.required}>
                            <InlineError field={nationality}>
                                <SelectInput
                                    {...blood_group.inputProps}
                                    placeholder="Select blood group"
                                    options={mapStringListToOptions(bloodGroupOptions, { transformCase: false })}
                                />
                            </InlineError>
                        </Label>
                        <Label text="Nationality" required={nationality.required}>
                            <InlineError field={nationality}>
                                <SelectInput
                                    {...nationality.inputProps}
                                    placeholder="Select nationality"
                                    options={mapCountriesJSONtoOptions()}
                                />
                            </InlineError>
                        </Label>
                        <Label text="State of Origin" required={state_of_origin.required}>
                            <InlineError field={state_of_origin}>
                                <SelectInput
                                    {...state_of_origin.inputProps}
                                    placeholder="Select state of origin"
                                    options={mapStatesInNigeriaJSONtoOptions(statesInNigeriaJSON)}
                                />
                            </InlineError>
                        </Label>
                        <Label text="L.G.A. of Origin" required={lga_of_origin_id.required}>
                            <InlineError field={state_of_origin}>
                                <SelectInput
                                    {...lga_of_origin_id.inputProps}
                                    placeholder="Select l.g.a. of origin"
                                    options={mapLgastoOptions(lgas, state_of_origin.raw)}
                                />
                            </InlineError>
                        </Label>
                        <Label text="Email" required={email.required}>
                            <InlineError field={email}>
                                <TextInput {...email.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Mobile Number" required={mobile_phone.required}>
                            <InlineError field={mobile_phone}>
                                <PhoneInput {...mobile_phone.inputProps} />
                            </InlineError>
                        </Label>
                        <Label text="Phone" required={phone.required}>
                            <InlineError field={phone}>
                                <PhoneInput {...phone.inputProps} />
                            </InlineError>
                        </Label>
                        <Label
                            text="Health Info"
                            required={health_info.required}
                            style={display.isTablet ? { gridColumn: "span 2" } : null}
                        >
                            <MultilineInput {...health_info.inputProps} />
                        </Label>
                    </div>
                </Card>

                <StudentGuardianRelations
                    student={addStudentFormInstance}
                    handleAssignGuardian={this.handleAssignGuardian}
                    handleUnassignGuardian={removeGuardian}
                />

                <div className="AddStudent-saveButton">
                    <Button
                        buttonType="success"
                        fullwidth
                        onClick={this.handleSubmit}
                        disabled={studentStoreLoading}
                    >
                        Save and add another
                    </Button>
                    <Button
                        fullwidth
                        onClick={this.handleSubmitAndExit}
                        disabled={studentStoreLoading}
                    >
                        Save student
                    </Button>
                </div>
            </div>
        )
    }
}
AddStudent.propTypes = {
    editMode: PropTypes.bool
}
AddStudent.defaultProps = {
    editMode: false
}
export default AddStudent

function mapLgastoOptions(lgas, stateCode) {
    const options = []
    lgas.forEach(({ id: value, attributes }) => {
        const { name: text, state_code } = attributes
        if (stateCode) {
            if (stateCode === state_code) {
                options.push({ text, value })
            }
        } else {
            options.push({ text, value })
        }
    })
    return options
}

const UploadPhoto = inject("display")(observer(({ display, field }) => {
    const InputControl = (
        <PhotoInput
            style={display.isDesktop ? { gridRow: "span 2" } : null}
            {...field.inputProps}
        />
    )
    if (display.isDesktop) {
        return InputControl
    }
    return (
        <Label text="Upload Picture" required={field.required}>
            <InlineError field={field}>
                {InputControl}
            </InlineError>
        </Label>
    )
}))